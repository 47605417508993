.App {
  direction: rtl !important;
  font-family:"openSansnormal" !important ;
  height: 100%;
  background-color: #f3f2f2c7;

}
html{
  background-color: #f3f2f2c7;
}
.coupondiv-succ{
  color: #208B2A !important;
}
.coupondiv-faile{
  color: red !important;
}
.UseCoupon{
  cursor: pointer;
  color: #1890ff;
}
.PaymentInput{
  max-width: 300px;
  margin: auto;
  border:none !important;
  border-bottom: 1px solid #1890ff !important;
  padding: 5px 10px !important;
  outline: none !important;
  
}
.Loading{
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1000;
  background-color: white;
  
  opacity: 0.8;
}
.loadingbord{
  position: absolute;
  left: 50%;
  top: 40%;
  right: 50%;
  
  


  
  


}
.PaymentRow{
  width: 36%;
  margin: auto !important;
}
.PaymentInputSelect{
  width: 150px !important;

}
.PaymentInput:focus {border-color:rgba(100,100,100,1)!important;
  -webkit-box-shadow: none!important;
      -moz-box-shadow: none!important;
      box-shadow: none!important;
  }
.btnblock{
  width: 100%;
}
.payment{
  background: transparent linear-gradient(180deg, #35A0C8 0%, #00AEEF 100%) 0% 0% no-repeat padding-box !important;
  border-color:white !important;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 300px !important;
  font-family: "fb_bold" !important;
  font-size: 20px !important;
  margin-top: 10px;
  height: 40px;
}
.number span {cursor:pointer; }

.minus, .plus{
  width:25px;
  height:25px;
  background: white;
  border-radius:13px;
  
  border: 1px solid #b1aeae;

  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.number input{
  height:25px;
  width: 52px;
  text-align: center;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  border: #fbfbfb;
}
.orderData{
  width: 50%;
  margin: auto !important;
}
.orderDetailspage h1{
  font-size: 20px;
  color: #4E5861;
  font-weight: bold;


}
.checkoutline{
  width: 260px;
  height: 2px;
  border: 1px dashed #CFCFCF;
  margin-top: 15px;
}
#inline-checkbox-1{
  float: right !important;
  margin-right: -1.5em !important;
}
h1{
  font-family: "fb_bold";
}
.orderdetailsPAge{
  margin-top: 50px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.inputblock{
  position: relative;
  margin-top:30px;
}

.inputblock-Amount{
  position: relative;
  font-size: 15px;
  font-family: "fb_bold";

}
.inputlabel{
  position: absolute;
  right: 10px;
    top: -13px;
    background-color: white;

}
.orderDetailspage label{
  font-size: 15px;
  color: #4E5861;

}
.space{
  margin-top: 30px !important;
}
.cust-a{
  margin-right: -62px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mainEventCard img{
  max-width: 100%;
  max-height: 100%;

}
.CardPhoto::after{
  content: '';
    display: block;
    height: 100%;
    width: 0px;
    margin: 0 0 0 10px;
    /* background-color: #ffffff; */
    position: absolute;
    top: 0px;
    left: -10px;
    border-left: 5px dashed #ffffff;

}
.CardPhoto{
  height: 100%;
  position: relative;
}
.CardPhoto img{
  min-height: 100%;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
 /*  border-top-left-radius: 5px; */
  /* border-bottom-left-radius: 5px; */
  max-width:100%;
  
}

.addtionalEventCard{
/*   min-height: 300px; */
  background: #FBFBFB 0% 0% no-repeat padding-box;
  border-radius: 20px;
  box-shadow: 0px 3px 35px #00000029;
  margin-top: 35px;

}
.Close{
  opacity: 0.5;
}
.Open{
  opacity: 1;
}
.numofcardssapn{
  font-family: "fb_bold";
  font-size: 15px;

}
.CrowdIcone{
  width: 25px;
height: 19px;
margin-bottom: 6px;
margin-left: 4px;

}
.mainEventCard{
  min-height: 320px;
  background: #FBFBFB 0% 0% no-repeat padding-box;

  border-radius: 20px;
  box-shadow: 0px 3px 65px #00000029;
  margin-top: 35px;

}
.CardTextAdt{
  padding: 45px 50px 30px;
  border-left: 3px dashed #B8B4AE;
  height: 100%;
}

.CardTextAdt h1{
  font-family: "fb_bold";
  font-size: 34px;
  color: #4E5861;
}
.CardText{
  padding: 40px 20px 30px;
  border-left: 3px dashed #B8B4AE;
  height: 100%;
}
.TotalCarT{
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 160px;
  background: #FBFBFB 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;

}
.TotalCarT{
  padding: 15px 35px 0px 0px;
}
.MainCheckOutTitle{
  font-size:21px;
  font-family: "fb_bold";
  color: #565656;


}
.TotalPriceCheckoutSession{
  text-align: center;
}
.TotalPriceCheckoutSession span{

 font-size:35px;
  font-family: "fb_bold";
  color: #40AE49;


}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #565656;
  font-size: 16px;
}
li{
  padding-top: 5px;
}
.MainCheckoutPricesList{
  font-family: "fb_bold";
}
.SepLine{
  border-left: 1px dashed #CFCFCF;

}
.CardPrices{
  margin-top: 10%;

}
.OpenTimes b{
  font-family: "fb_bold";
}
.OpenTimes img{
  padding-bottom: 2px;
  padding-left: 3px;
  padding-right: 3px;
}
.OpenTimes{
  background: #ECECEC 0% 0% no-repeat padding-box;
border-radius: 5px;
width: fit-content;
padding: 5px;
font-size: 17px;
color: #4E5861;


}
.App-link {
  color: #61dafb;
}

.btn-success{
  background-color:#208B2A !important;
  width: 140px;
  height: 44px;
}
.pricespan{
  font-family: "fb_bold";
}
.form-floating>label{
  left: auto !important;
}
.spetiolcal{
  padding-right: 2px !important;
  padding-left: 0px !important;
}
.CardText h1{
  font-family: "fb_bold";
  font-size: 34px;
  color: #4E5861;
}
.btnaddtobask{
  font-family:  "fb_bold";
  font-size: 20px !important;
  background: url("./btn-backround.png") !important;
  border-color:#fbfbfb !important;
  width: 170px;
  height: 46px !important;

}

.form-check{
  padding-right: 2.5em;
}
.CardText p{
  font-size: 15px;
  color: #4E5861;
}
.Terms{
  display: block;

}
.Terms label{
  display: inline;
  float: left;
}
.Terms label{
  
  float: right;
}
.nextbtn{
  background: transparent linear-gradient(180deg, #35A0C8 0%, #00AEEF 100%) 0% 0% no-repeat padding-box;
  border-color:white !important;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 170px !important;
  font-family: "fb_bold" !important;
  font-size: 20px !important;
  margin-top: 10px;
  background: url("./image.png") !important;
  height: 50px;

}
@font-face {
  font-family: "fb_bold";
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/OpenSansHebrew-Bold.woff') format("opentype"); /* here you go, IE */
}
@font-face {
  font-family: "openSansnormal";
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/OpenSansHebrew-Regular.woff') format("opentype"); /* here you go, IE */
}

.plusebtn{
  font-size: 40px;
  color: #00AEEF;
}
.RestAttractions{
  margin-top: 45px;
  direction: rtl;

}
.spticonarrow{
  position: absolute;


}
.continuetext::before{
  font-family: "Font Awesome 5 Free";
  content: "\f053";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.RestAttractions h1{
  font-size: 25px;
  color: #4E5861;
  font-family: "fb_bold";
}
.mainEventCard .CardPrices span{
  font-family: "fb_bold";

}
.mainEventCard .CrowdIcone{
  margin-left: 5px;
  margin-right: -38%;
  
} 

.App-header{
  padding-bottom: 0.5%;
  padding-top: 0.5%;

  background-color:#E9E7D7;



}
.icone{
  padding-left: 10px;
}
.welcomeSession h1{
  font-size: 28px;

}
.welcomeSession{
  
  margin-top: 30px;
  border-radius: 15px;
 
}
.Reception .col{
  text-align: center !important;

  
}
.ReceptionOrder .col{
  text-align: center !important;
  margin-top: 8px;
  font-size: 15px;



}
.ReceptionOrder .col::after{
  content: "";
  display: block;
  border-bottom: 1px solid #E6E6E6;
  margin-top: 15px;

  width: 110%;
}
.Reception span{
  font-weight: bold;
  color: #4E5861;
  font-size: 15px;
  font-family: "fb_bold" !important;

}
.ticketicone{
  margin-left: 4px;
}
.welcomeSession{
  text-align: center;
  padding-top: 20px;
  color: #4E5861;
}
.ticket-btn{
  width:300px;
  margin: 10px 10px;
  border-color:white;
  font-family: "fb_bold";
  height: 47px;
  font-size: 20px !important;

}
.green{
  background: transparent linear-gradient(180deg, #40AE49 0%, #208B2A 100%) 0% 0% no-repeat padding-box;
  border-color:white !important;
}
.grey{

  background: #D2D2D2 0% 0% no-repeat padding-box !important;
  border-color:white !important;
  color: #565656 !important;
}

.alertify .ajs-dialog{
  max-width: 360px !important;

}
.alertify .ajs-header{
  font-family: 'Open Sans Hebrew', serif !important;
  border-bottom: 0px solid white !important;
  font-weight: bold;
  text-align: center;
  border-bottom:1px solid #e5e5e5 !important;
}

.alertify .ajs-footer .ajs-buttons.ajs-primary{
  text-align:center !important;
 

}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok{
  color: black !important;
  font-size: 22px !important;
}
.alertify .ajs-dialog{
  border-radius: 6px !important;
  direction: rtl !important;
}
.alertify .ajs-commands button.ajs-close{
  width: 45px;
  height: 55px;

}
.alertify .ajs-header{
  border-radius: 6px;
  font-family: "fb_bold" !important;
  font-size: 26px;
  direction: rtl !important;
}
.errormessagealert{
  color: red !important;
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok{
  background-color: white;
  border: 0px;
  font-family: "fb_bold" !important;
  font-size: 23px !important;
  

}
.alertify .ajs-body .ajs-content{
  min-height: 115px;
  font-size: 18px;
  text-align: center;
}

.alertify .ajs-footer{
  border-radius: 6px;
  border-top:1px solid #e5e5e5;
}

.greentotalprice{
  font-family: "fb_bold";
  font-size: 26px;
  color: #40AE49;
  margin-right: 20px;

}
.TotalPriceOrder{
  text-align: center;
  margin-top: 50px;

}
.TotalPriceOrder h1{
  font-size: 30px !important;
  font-family: "fb_bold";
  color: #4E5861;

}
.MainLogoB img{
  display: block;
  margin: auto;

}
.MainLogoB{
 
}
.Error{
  color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {

  .CardText{
    
    border-left: 0px dashed #B8B4AE;
   
  }

  .mainEventCard .CrowdIcone{
    margin-right: 0%;

  }
  .CardTextAdt{
    border-left: 0px;
  }
  .CardPhoto::after{
    display: none;
  }
  .PaymentRow{
    width: 100%;
  }
  .orderData{
    width: 100%;
  }
  .TotalCarT{
    text-align: center;
  }
  .CardPrices select{
    margin: auto;
  }
  .checkoutline{
    width: 90%;
  
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: auto;
    border-bottom: 1px dashed #CFCFCF ;
   

  }
  .TotalCostAtr{
    text-align: center;
    display: block;
  }
  .number{
   padding-right: 20%;
  }
  .midRow{
    margin: auto !important;

  }
  .CardPrices{
    margin-top: -20px;
  }
  .TotaladdisionalPrice{
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .CardTextAdt h1{
    font-size: 25px;

  }
  .CardTextAdt{
    padding: 15px 15px 15px;
  }
  .RestAttractions h1{
    font-size: 25px;
    text-align: center;

  }
  .CardText h1{
    font-size: 25px;

  }
 
}

